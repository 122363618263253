import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import axios from 'axios';

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import Login from '../views/Login.vue'
import ChangePassword from '../views/ChangePassword.vue'
import Portal from '../views/Portal.vue'
import Folders from '../views/Folders.vue'
//import UserHome from '../views/clientUserHome.vue'

var user: any = null;
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Default',
    meta: {
      title: 'MLS Document Portal'
    },
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: async (to, from, next) => {
      // ...
      await checkSession();
      if (user?.changePassword) {
        next({ name: "ChangePassword" })
      } else if (user) {
        next({ name: "Portal" });
      } else {
        next();
      }
    }
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    component: ChangePassword,
    beforeEnter: async (to, from, next) => {
      await checkSession();
      if (user) {
        next();
      } else {
        next({ name: "Login" });
      }
    }
  },
  {
    path: '/portal',
    name: 'Portal',
    meta: {
      title: 'MLS Document Portal'
    },
    beforeEnter: async (to, from, next) => {
      await checkSession();
      to.params.user = user;

      var chosenTab: any = { name : "navReports"};
      to.params.chosenTab = chosenTab;

      if (user?.changePassword) {
        next({ name: "ChangePassword" });
      } else if (user) {
        next();
      } else {
        next({ name: "Login" });
      }
    },
    component: Portal,
    props: true
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import( '../views/About.vue')*/
  }
]

function checkSession() {
  let sessionUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_CHECK_SESSION;
  return axios.get(sessionUrl,
    {
      withCredentials: true
    }
  )
    .then(response => {
      user = response?.data
      return response?.data;
    })
    .catch(error => {
      user = null;
      return null;
    });
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
