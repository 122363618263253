<template>
    <div class="card text-left">
        <div class="card-body">
            <div class="row">
                <div class="col col-8-auto">
                    <h5 class="card-title font-weight-bold">
                        Reports
                    </h5>
                </div>
                <div class="col col-4 text-right" v-show="user.admin">
                    <a href="" class="mr-3" title="Add Folder" data-toggle="modal" data-target="#addfoldermodal"><FontAwesomeIcon icon="folder-plus" size="lg"/></a>
                    <a href="" class="mr-3" title="Upload File" data-toggle="modal" data-target="#uploadfilemodal"><FontAwesomeIcon icon="cloud-upload-alt" size="lg"/></a>
                    <a href="" class="mr-3" title="Edit Folder Permission" data-toggle="modal" data-target="#folderpermissionmodal" @click="this.retrieveFolderPermission"><FontAwesomeIcon icon="user-lock" size="lg"/></a>
                </div>
            </div>
            <h6 class="card-subtitle text-muted font-italic">
                Location: <span v-for="(pathitem, index) in path" :key="pathitem.id"> {{ index >= 1 ? ">" : ""}} <a href="" v-if="index < (path.length - 1)" v-on:click.prevent.stop="folderNavigate(pathitem)">{{ pathitem.name }}</a> {{ index == (path.length-1) ? pathitem.name : ""}}</span>
            </h6>
            <!-- <div class="filter-button clickable text-right" @click="showReportFilter"><FontAwesomeIcon icon="sliders-h" size="lg"/></div>
            <br/>
            <div class="card bg-light p-2" id="reportFilter" style="display:none">
                <div class="card-title font-weight-bold">Report Filter</div>
                <input type="text" v-model="reportFilter.name" placeholder="Folder/Report name">
                <div class="text-right filter-apply-div"><button type="button" class="btn btn-primary" v-on:click="filterReport">Apply</button></div>
            </div> -->
            <table class="table table-striped mt-2 table-hover">
                <thead>
                   <tr>
                        <th scope="col"></th>
                        <th scope="col" class="clickable" @click="sortByColumn('name')">
                            Name
                            <span v-if="reportOrder.by=='name'">
                                <FontAwesomeIcon v-if="reportOrder.direction=='asc'" icon="sort-down"/>
                                <FontAwesomeIcon v-if="reportOrder.direction=='desc'" icon="sort-up"/>
                            </span>
                        </th>
                        <th scope="col">Size</th>
                        <th scope="col" class="clickable" @click="sortByColumn('modified')">
                            Modified
                            <span v-if="reportOrder.by=='modified'">
                                <FontAwesomeIcon v-if="reportOrder.direction=='asc'" icon="sort-down"/>
                                <FontAwesomeIcon v-if="reportOrder.direction=='desc'" icon="sort-up"/>
                            </span>
                        </th>
                        <th scope="col" v-if="user.admin">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item) in folders" :key="item.id">
                        <td>
                            <FontAwesomeIcon icon="folder"/>
                        </td>
                        <td><a href="#" v-on:click.stop.prevent="itemSelected(item)">{{item.name}}</a></td>
                        <td><span>{{item.size}}</span></td>
                        <td>{{item.modified}}</td>
                        <td v-if="user.admin">
                            <a href="" class="mr-3" data-toggle="modal" data-target="#editfoldermodal" @click="this.selectedItem=item;this.editedFolderName=item.name"><FontAwesomeIcon icon="edit"/></a>
                            <a href="" class="mr-3" data-toggle="modal" data-target="#deletefoldermodal" @click="this.selectedItem=item"><FontAwesomeIcon icon="trash-alt"/></a>
                        </td>
                    </tr>
                    <tr v-for="(item) in reports" :key="item.id">
                        <td>
                            <FontAwesomeIcon icon="file-alt"/>
                        </td>
                        <td><a href="#" v-on:click.stop.prevent="itemSelected(item)">{{item.name}}</a></td>
                        <td><span>{{item.size}}</span></td>
                        <td>{{item.modified}}</td>
                        <td v-if="user.admin">
                            <a href="" class="mr-3" data-toggle="modal" data-target="#deletefoldermodal" @click="this.selectedItem=item"><FontAwesomeIcon icon="trash-alt"/></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center" v-if="(folders == null || folders.length <=0) && (reports == null || reports.length <=0)">
                No records found.
            </div>
        </div>
        <!-- Folder add modal -->
        <div class="modal" tabindex="-1" role="dialog" id="addfoldermodal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add Folder</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="text-muted font-italic">Location: <span v-for="(pathitem, index) in path" :key="pathitem.id" > {{ index >= 1 ? ">" : ""}} {{ pathitem.name }} </span></p>
                        <p class="text-danger" v-if="addFolderError != ''"> {{ addFolderError }} </p>
                        <p>Folder Name: <input type="text" v-model="newFolderName"/></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" v-on:click="addFolder">Add</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- File upload modal -->
        <div class="modal" tabindex="-1" role="dialog" id="uploadfilemodal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Upload File</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="text-muted font-italic">Location: <span v-for="(pathitem, index) in path" :key="pathitem.id" > {{ index >= 1 ? ">" : ""}} {{ pathitem.name }} </span></p>
                        <p class="text-danger" v-if="uploadFileError != ''"> {{ uploadFileError }} </p>
                        <div class="card bg-light" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                            <div class="card-body">
                                <input type="file" multiple name="files" id="filesUploadInput" class="hidden overflow-hidden absolute" @change="selectUploadFiles" ref="uploaded"/>
                                <label for="filesUploadInput" class="block cursor-pointer">
                                <div>
                                    Drag and drop files here 
                                    or <span class="underline">click here</span> to upload their files
                                </div>
                                </label>
                                <ul id=uploadedFileList v-if="this.files2buploaded.length">
                                    <li class="text-sm p-1" v-for="file in files2buploaded" :key="file.name">
                                        <span class="filename">{{ file.name }}</span>
                                        <button class="ml-2 remove-file-button" type="button" @click="remove(files2buploaded.indexOf(file))" title="Remove file">X</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" v-on:click="uploadFiles">Upload</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Folder permission modal -->
        <div class="modal" tabindex="-1" role="dialog" id="folderpermissionmodal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Folder Permission</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul id="permission-users-list">
                            <li v-for="user in folderPermissionUsers" :key="user.userId">
                                <input type="checkbox" :id="user.userId" :name="user.userId" :value="user.hasAccess" v-model="user.hasAccess">&nbsp;<label :for="user.userId">{{user.name}}</label>
                            </li>
                        </ul>
                        <div class="text-align" v-if="folderPermissionUsers == null || folderPermissionUsers.length <=0">
                            No user found
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" v-on:click="updateFolderPermission">Update</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Folder Edit modal -->
        <div class="modal" tabindex="-1" role="dialog" id="editfoldermodal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Edit Folder</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="text-muted font-italic">Path: <span v-for="(pathitem, index) in path" :key="pathitem.id" > {{ index >= 1 ? ">" : ""}} {{ pathitem.name }} </span></p>
                        <p class="text-danger" v-if="editFolderError != ''"> {{ editFolderError }} </p>
                        <p>Folder Name: <input type="text" v-model="editedFolderName" /></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" v-on:click="editFolder(this.selectedItem)">Edit</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Folder Delete modal -->
        <div class="modal" tabindex="-1" role="dialog" id="deletefoldermodal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-if="selectedItem.type == 'folder'">Delete Folder</h5>
                        <h5 class="modal-title" v-if="selectedItem.type == 'report'">Delete Report</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="text-muted font-italic">Path: <span v-for="(pathitem, index) in path" :key="pathitem.id" > {{ index >= 1 ? ">" : ""}} {{ pathitem.name }} </span></p>
                        <p class="text-danger" v-if="deleteFolderError != ''"> {{ deleteFolderError }} </p>
                        <p class="alert alert-danger" v-if="selectedItem.type == 'folder'">Are you sure you want to delete <strong>'{{selectedItem.name}}'</strong> and everything under it?</p>
                        <p class="alert alert-danger" v-if="selectedItem.type == 'report'">Are you sure you want to delete <strong>'{{selectedItem.name}}'</strong>?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" v-on:click="deleteItem(selectedItem)">Delete</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.clickable {
    cursor: pointer;
    color: #0078FF;
}
.clickable:hover {
    color: #0156B3
}
.filter-button {
    display: inline;
    float: right;
    clear: both;
}
#reportFilter {
    margin-top: 10px;
}
.filter-apply-div {
    margin-top: 10px;
}
.bg-green {
    background-color: lightgreen;
}
.hidden {
    display: none;
}
.underline {
    text-decoration: underline;
}
.cursor-pointer {
    cursor: pointer;
}
#uploadedFileList {
    list-style: none;
    font-size: 14px;
    padding-left: 15px;
}
.remove-file-button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: red;
    font-weight: bold;
}
#permission-users-list {
    list-style: none;
}
</style>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moment from 'moment'

const $ = require('jquery');
const axios = require('axios');

export default {
    components : { FontAwesomeIcon, moment },
    mounted() {
        const _this = this;

        console.log("User in folder:", _this.user);
        // clear data in add folder modal
        $('#addfoldermodal').on('hide.bs.modal', function(e) {
            _this.addFolderError = "";
            _this.newFolderName = "";
        });

        // clear data in upload folder modal
        $('#uploadfilemodal').on('hide.bs.modal', function(e) {
            _this.uploadFileError = "";
            _this.files2buploaded = "";
            $('.custom-file-label').html("");
        });

        // clear data in upload folder modal
        $('#editfoldermodal').on('hide.bs.modal', function(e) {
            _this.editFolderError = "";
            _this.selectedItem = {};
            _this.editedFolderName = "";
        });
    },
    data() {
        let defaultFolder = {name: "Main", id: "root", type: "folder"};
        let defaultOrder = {by:"name", direction:"asc"};
        let defaultFilter = {name: ""};
        return {
            path: [defaultFolder],
            currentFolder: defaultFolder,
            defaultOrder: defaultOrder,
            reportOrder: defaultOrder,
            defaultFilter: defaultFilter,
            reportFilter: defaultFilter,
            folders: [],
            reports: [],
            errors: {},
            newFolderName: "",
            addFolderError: "",
            files2buploaded: [],
            uploadFileError: "",
            editFolderError: "",
            selectedItem: "",
            editedFolderName: "",
            deleteFolderError: "",
            folderPermissionUsers: []
        }
    },
    props: ['user'],
    mounted() {
        this._getItems(this.currentFolder);
    },
    methods: {
        itemSelected(item) {
            if (item.type == "folder") {
                // TODO: update item list
                this.currentFolder = item;
                this._getItems(item);               
                this.path.push(item);
            } else if (item.type == "report") {
                // TODO: Download the file from backend
                this.downloadFile(item);
            }
        },
        folderNavigate(item) {
            if (item.type == "folder") {
                var itemposition = this.path.findIndex(pathitem => pathitem.id == item.id && item.type=="folder");
                this.path.splice(itemposition+1);
                
                this.currentFolder = item;
                this._getItems(this.currentFolder);
            } else {
                console.log("Error, cannot navigating to file");
            }
        },
        sortByColumn(by) {
            this.reportOrder.by = by;
            this.reportOrder.direction = this.reportOrder.direction == "asc" ? "desc" : "asc";

            this.folders.sort(this.sortItems);
            this.reports.sort(this.sortItems);
        },
        sortItems(a, b){
            if (a[this.reportOrder.by] < b[this.reportOrder.by] ) {
                if(this.reportOrder.direction == "asc") {
                    return -1;
                } else {
                    return 1;
                }
                
            }
            if (a[this.reportOrder.by] > b[this.reportOrder.by] ) {
                if(this.reportOrder.direction == "asc") {
                    return 1;
                } else {
                    return -1;
                }
            }
            return 0;
        },
        showReportFilter(event) {
            $('#reportFilter').toggle();
        },
        filterReport(event) {
            this._getItems(this.currentFolder);
        },
        addFolder() {
            // TODO: Call backend to save new folder
            // saveFolder(this.newFolderName, currentFolder.id);

            var success = true;
            if (success) {
                let createFolderUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_CREATE_FOLDER;
                createFolderUrl += "/" + this.currentFolder.id;
                axios.post(createFolderUrl, 
                {
                    "folderName": this.newFolderName
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true
                }
                )
                .then(response => {
                    this.newFolderName = "";
                    this.addFolderError = "";
                    // update items after save
                    this._getItems(this.currentFolder);

                    $('#addfoldermodal').modal('hide');
                })
                .catch(error => {
                    this.addFolderError = error.response.data.message;
                    if(error.response.data.statusCode == 440) {
                        this.$router.push("/login");
                    }
                });
                
            } else {
                this.addFolderError = "Error adding folder!";
            }
        },
        resetUploadFiles() {
            document.getElementById('filesUploadInput').value = "";
            this.files2buploaded = [];
        },
        selectUploadFiles() {
            for(let file of this.$refs.uploaded.files) {
                this.files2buploaded.push(file);
            }
        },
        remove(i) {
            this.files2buploaded.splice(i, 1);
        },
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('bg-green-300')) {
                event.currentTarget.classList.remove('bg-light');
                event.currentTarget.classList.add('bg-green');
            }
        },
        dragleave(event) {
            // Clean up
            event.currentTarget.classList.add('bg-green');
            event.currentTarget.classList.remove('bg-light');
        },
        drop(event) {
            event.preventDefault();
            for(let file of event.dataTransfer.files) {
                this.files2buploaded.push(file);
            }

            // Clean up
            event.currentTarget.classList.add('bg-light');
            event.currentTarget.classList.remove('bg-green');
        },
        async uploadFiles() {
            if (this.files2buploaded.length > 0) {
                let files = [];
                let fileObject = {};

                for (let file of this.files2buploaded) {
                    let base64Files = await this.toBase64File(file);
                    fileObject = {
                        "fileName": file.name,
                        "fileSize": file.size,
                        "file": base64Files
                    }
                    files.push(fileObject);
                }

                let createReportUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_CREATE_REPORT;
                axios.post(createReportUrl, 
                {
                    "folderId": this.currentFolder.id,
                    "files": files
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true
                }
                )
                .then(response => {
                    // update items after update
                    this.resetUploadFiles();
                    this._getItems(this.currentFolder);
                    this.uploadFileError = "";

                    $('#uploadfilemodal').modal('hide');
                })
                .catch(error => {
                    this.uploadFileError = error.response.data.message;
                    if(error.response.data.statusCode == 440) {
                        this.$router.push("/login");
                    }
                });

                
            } else {
                this.uploadFileError = "Please choose a file to upload";
            }
        },
        async toBase64File (file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        editFolder(folder) {
            // TODO: save the folder
            var success = true;
            if (success) {
                let updateFolderUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_UPDATE_FOLDER;
                updateFolderUrl += "/" + folder.id;
                axios.put(updateFolderUrl, 
                {
                    "folderName": this.editedFolderName,
                    "parentFolderId": this.currentFolder.id
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true
                }
                )
                .then(response => {
                    this.editFolderError = "";
                    // update items after update
                    this._getItems(this.currentFolder);

                    $('#editfoldermodal').modal('hide');
                })
                .catch(error => {
                    this.editFolderError = error.response.data.message;
                    if(error.response.data.statusCode == 440) {
                        this.$router.push("/login");
                    }
                });
            } else {
                this.editFolderError = "Error editing folder name!";
            }
        },
        deleteItem(item) {
            if(item.type == "folder") {
                this.deleteFolder(item);
            }else {
                this.deleteReport(item);
            }
        },
        deleteFolder(folder) {
            // TODO: Delete folder
            var success = true;
            if (success) {
                let deleteFolderUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_DELETE_FOLDER;
                deleteFolderUrl += "/" + folder.id;
                axios.delete(deleteFolderUrl, 
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true
                }
                )
                .then(response => {
                    // update items after delete
                    this._getItems(this.currentFolder);

                    $('#deletefoldermodal').modal('hide');
                })
                .catch(error => {
                    this.deleteFolderError = error.response.data.message;
                    if(error.response.data.statusCode == 440) {
                        this.$router.push("/login");
                    }
                });
            } else {
                this.deleteFolderError = "Error deleting folder!";
            }
        },
        deleteReport(report) {
            // TODO: Delete folder
            var success = true;
            if (success) {
                let deleteReportUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_DELETE_REPORT;
                deleteReportUrl += "/" + report.id;
                axios.delete(deleteReportUrl, 
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true
                }
                )
                .then(response => {
                    // update items after delete
                    this._getItems(this.currentFolder);

                    $('#deletefoldermodal').modal('hide');
                })
                .catch(error => {
                    this.deleteFolderError = error.response.data.message;
                    if(error.response.data.statusCode == 440) {
                        this.$router.push("/login");
                    }
                });
            } else {
                this.deleteFolderError = "Error deleting report!";
            }
        },
        downloadFile(item) {
            let getContentsUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_RETRIEVE_REPORT;
            getContentsUrl += "/" + item.id;
            axios.get(getContentsUrl, 
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            }
            )
            .then(response => {
                let a = document.createElement("a");
                a.href = "data:" + response.headers["content-type"] +";base64," + response.data.file;
                a.download = response.data.fileName;
                a.click();
            })
            .catch(error => {
                console.log("Error downloading file");
                console.log(error);
                if(error.response.data.statusCode == 440) {
                    this.$router.push("/login");
                }
            });
        },
        _getItems(item) {
            let getContentsUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_LIST_CONTENTS;
            getContentsUrl += "/" + item.id;
            axios.get(getContentsUrl, 
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            }
            )
            .then(response => {
                let folders = [];
                let reports = [];
                for(let folder of response.data.folders) {
                    let item = {
                        "id": folder.folderId,
                        "name": folder.folderName,
                        "modified": moment(folder.modifiedDate).format("DD/MM/YYYY HH:mm:ss"),
                        "type": "folder"
                    }
                    folders.push(item);
                }
                folders.sort(this.sortItems);
                this.folders = folders;

                for(let report of response.data.reports) {
                    let item = {
                        "id": report.reportId,
                        "name": report.fileName,
                        "size": (report.fileSize / 1000).toFixed(1) + "KB",
                        "modified": moment(report.modifiedDate).format("DD/MM/YYYY HH:mm:ss"),
                        "type": "report"
                    }
                    reports.push(item);
                }
                reports.sort(this.sortItems);
                this.reports = reports;
                return true;
            })
            .catch(error => {
                console.log("Error listing folders");
                if(error.response.data.statusCode == 440) {
                    this.$router.push("/login");
                }
            });
        },
        retrieveFolderPermission() {
            let retrieveFolderPermissionUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_ADMIN_RETRIEVE_FOLDER_PERMISSION;
            retrieveFolderPermissionUrl += "/" + this.currentFolder.id;
            axios.get(retrieveFolderPermissionUrl, 
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            }
            )
            .then(response => {
                this.folderPermissionUsers = response.data;
                return true;
            })
            .catch(error => {
                console.log("Error retrieving folder permission");
                if(error.response.data.statusCode == 440) {
                    this.$router.push("/login");
                }
            });
        },
        updateFolderPermission() {
            let updateFolderPermissionUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_ADMIN_UPDATE_FOLDER_PERMISSION;
            updateFolderPermissionUrl += "/" + this.currentFolder.id;
            axios.put(updateFolderPermissionUrl, 
            {
                "permissions": this.folderPermissionUsers
            },
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            }
            )
            .then(response => {
                $('#folderpermissionmodal').modal('hide');
                return true;
            })
            .catch(error => {
                console.log("Error retrieving folder permission");
                if(error.response.data.statusCode == 440) {
                    this.$router.push("/login");
                }
            });
        }
    }
}
</script>