<template>
    <div>
        <div class="changePassword">
            <LogoBanner />
            <h4 class="mb-3">Marchwood Laboratory Test Results Document Portal</h4>
            <h6 class="text-danger">Password Change Required</h6>
            <form id="changePasswordForm" class="text-center form-changePassword" @submit.prevent="submit" novalidate>
                <input id="_currentPw" class="form-control" type="password" name="currentPw" placeholder="Current Password" v-model="fields.currentPw"/>
                <div v-if="errors && errors.currentPw" class="text-danger error">{{ errors.currentPw[0] }}</div>
                <input id="_newPw" class="form-control" type="password" name="newPw" placeholder="New Password" v-model="fields.newPw">
                <div v-if="errors && errors.newPw" class="text-danger error">{{ errors.newPw[0] }}</div>
                <input id="_confirmNewPw" class="form-control" type="password" name="confirmNewPw" placeholder="Confirm Password" v-model="fields.confirmNewPw">
                <div v-if="errors && errors.confirmNewPw" class="text-danger error">{{ errors.confirmNewPw[0] }}</div>
                <button class="btn btn-lg btn-primary submit-button" type="submit">Reset Password</button>
            </form>
        </div>
    </div>
</template>

<style>
    .changePassword {
        padding-top:40px;
        padding-bottom:40px;
    }
    .signin h4 {
        padding-top:40px;
        padding-bottom:40px;
    }
    .form-changePassword {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
    .form-changePassword .checkbox {
        font-weight: 400;
    }
    .form-changePassword .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    .form-changePassword .form-control:focus {
        z-index: 2;
    }
    .form-changePassword input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .form-changePassword input[type="password"] {
        margin-bottom: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .text-danger.error {
        margin-bottom: 10px;
        white-space: pre-wrap;
    }
    .submit-button {
        margin-top: 10px;
    }
</style>

<script>
import LogoBanner from '@/views/LogoBanner.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import owasp from 'owasp-password-strength-test';
import axios from 'axios';

owasp.config({
    allowPassphrases: true,
    maxLength: 128,
    minLength: 8,
    minPhraseLength: 20,
    minOptionalTestsToPass: 4
});

export default { 
    components : {
        LogoBanner,
        FontAwesomeIcon,
        owasp,
        axios
    },
    data() {
        return {
            fields: {},
            errors: {},
        }
    },
  methods: {
    submit() {
        this.errors = {};
        var err = false;
        if (!this.fields.currentPw || this.fields.currentPw == "") {
            this.errors.currentPw = [];
            this.errors.currentPw.push("Please input Current Password");
            err = true;
        }

        if (!this.fields.newPw || this.fields.newPw == "") {
            this.errors.newPw = [];
            this.errors.newPw.push("Please input New Password");
            err = true;
        } else if(this.fields.currentPw == this.fields.newPw){
            this.errors.newPw = [];
            this.errors.newPw.push("Cannot use the same password");
            err = true;
        } else {
            //check password strength
            let passTestResult = owasp.test(this.fields.newPw);
            if (passTestResult.errors && passTestResult.errors.length > 0) {
                let error = ""
                for(let i = 0; i < passTestResult.errors.length; i++){
                    error += "- " + passTestResult.errors[i];
                    if(i < passTestResult.errors.length - 1){
                        error += "\n";
                    }
                }
                this.errors.newPw = [];
                this.errors.newPw.push(error);
                err = true;
            }
        }

        if (!this.fields.confirmNewPw || this.fields.confirmNewPw == "") {
            this.errors.confirmNewPw = [];
            this.errors.confirmNewPw.push("Please input Confirm Password");
            err = true;
        } else {
            if(this.fields.newPw != this.fields.confirmNewPw){
                this.errors.confirmNewPw = [];
                this.errors.confirmNewPw.push("New Password must match with Confirm Password");
                err = true;
            }
        }
        

        console.log("errors:", this.errors);
        console.log("router", this.$router);
        if (!err) {
            let changePasswordUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_USER_CHANGE_PASSWORD;
            axios.post(changePasswordUrl, 
            {
                oldPassword: this.fields.currentPw,
                newPassword: this.fields.newPw,
                confirmPassword: this.fields.confirmNewPw
            },
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            }
            )
            .then(response => {
                console.log("password changed successfully");
                this.$router.push("/portal");
            })
            .catch(error => {
                this.errors.confirmNewPw = [];
                this.errors.confirmNewPw.push(error?.response?.data?.message);
                if(error.response.data.statusCode == 440) {
                    this.$router.push("/login");
                }
            });
        }
    }
  }
};
</script>