<template>
    <nav class="navbar navbar-expand-lg">
      <img alt="Marchword" src="../assets/mls_logo.jpg" height="100" class="navbar-brand">
      <div >
        <span class="welcome text-secondary">Welcome {{user.name}}<FontAwesomeIcon class="clickable user-icon" title="user" icon="user" size="lg" data-toggle="modal" data-target="#updateusermodal" @click="this.retrieveUser"/><FontAwesomeIcon class="clickable logout-icon" title="logout" icon="sign-out-alt" size="lg" v-on:click="logout"/></span>
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item ml-auto" >
            <a class="nav-link" v-on:click.stop.prevent="toggleNav" name="navReports">Reports</a>
          </li>
          <li class="nav-item ml-auto" >
            <a class="nav-link" v-on:click.stop.prevent="toggleNav" name="navSearch">Search</a>
          </li>
          <li class="nav-item ml-auto" v-if='user.admin'>
            <a class="nav-link" v-on:click.stop.prevent="toggleNav" name="navAdmin">Admin</a>
          </li>
        </ul>
      </div>
      <!-- User update modal -->
      <div class="modal" tabindex="-1" role="dialog" id="updateusermodal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Update User</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p class="text-danger" v-if="updateUserError != ''"> {{ updateUserError }} </p>
              <p><strong>Account: {{ updatingUser.username }}</strong></p>
              <p>
                  Name: <input type="text" class="form-control" v-model="updatingUser.name" placeholder="Name"/>
              </p>
              <p>
                  Email: <input type="text" class="form-control" v-model="updatingUser.email" placeholder="Email"/>
              </p>
              <p>
                  Password: <input type="password" class="form-control" v-model="updatingUser.password" placeholder="Password. Blank to retain current password"/>
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" v-on:click="updateUser">Update</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </nav>
</template>
<style>
.user-icon {
  margin-left : 10px;
}
.logout-icon {
  margin-left : 15px;
}
.navbar-brand {
  margin-right:auto;
}
.welcome {
  text-align:right;
}
.nav-item {
  margin-left:10px;
  margin-right:10px;
  cursor: pointer;
}
a.nav-link:hover  {
  border-bottom: 3px solid rgb(181, 181, 182)
}

a.nav-link.active  {
  border-bottom: 3px solid #0156CD
}
.modal-body {
  text-align: left;
}
</style>
<script>

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import owasp from 'owasp-password-strength-test';

owasp.config({
    allowPassphrases: true,
    maxLength: 128,
    minLength: 8,
    minPhraseLength: 20,
    minOptionalTestsToPass: 4
});

const $ = require('jquery');
const axios = require('axios');

export default { 
    components : { FontAwesomeIcon },
    props : ['user', 'navTab'],
    data() {
      return {
        updatingUser: {},
        updateUserError: ""
      }
    },
    mounted() {
      this.toggleNav({ target: { name: this.navTab.name }});
    },
    methods: {
      toggleNav(evt) {
        var links = document.querySelectorAll('.nav-link');

        // Loop through each link
        for (var i = 0; i < links.length; i++) {
          // If the link is the one clicked, skip it
          if (links[i].name === evt.target.name) {
            links[i].classList.add('active');
            this.navTab.name = evt.target.name;
          } else {
            // Remove the .active class
            links[i].classList.remove('active');
          }
        }
      },
      retrieveUser() {
        let retrieveUserUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_RETRIEVE_USER;
        retrieveUserUrl += "/" + this.user.userId;
        axios.get(retrieveUserUrl, 
        {
          headers: {
            "Content-Type": "application/json"
          },
          withCredentials: true
        }
        )
        .then(response => {
          this.updatingUser = response.data;
        })
        .catch(error => {
          this.updateUserError = error.response.data.message;
          if(error.response.data.statusCode == 440) {
           this.$router.push("/login");
          }
      });
      },
      updateUser() {
        var success = true;
          let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!this.updatingUser.name) {
            this.updateUserError = "Please input name";
            success = false;
          } else if (!this.updatingUser.email) {
            this.updateUserError = "Please input email";
            success = false;
          } else if (!re.test(this.updatingUser.email)) {
            this.updateUserError = "Invalid email format";
            success = false;
          } else {
            if (this.updatingUser.password) {
                //check password strength
              let passTestResult = owasp.test(this.updatingUser.password);
              if (passTestResult.errors && passTestResult.errors.length > 0) {
                let error = ""
                for(let i = 0; i < passTestResult.errors.length; i++){
                  error += "- " + passTestResult.errors[i];
                  if(i < passTestResult.errors.length - 1){
                    error += "\n";
                  }
                }
                this.updateUserError = error;
                success = false;
              }
            }
          }

          if (success) {
            let updateUserUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_UPDATE_USER;
            updateUserUrl += "/" + this.user.userId;
            axios.put(updateUserUrl, 
            {
              "name": this.updatingUser.name,
              "email": this.updatingUser.email,
              "password": this.updatingUser.password
            },
            {
              headers: {
                "Content-Type": "application/json"
              },
              withCredentials: true
            }
            )
            .then(response => {
              this.updateUserError = "";
              $('#updateusermodal').modal('hide');
              return;
            })
            .catch(error => {
              console.log("error", error);
              this.updateUserError = error.response.data.message;

              if(error.response.data.statusCode == 440) {
                this.$router.push("/login");
              }
          });
        }
      },
      logout() {
        let logoutUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_USER_LOGOUT;
        axios.post(logoutUrl, 
        {
        },
        {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        }
        )
        .then(response => {
            this.$router.push("/login");
        })
        .catch(error => {
          console.log("logout error", error);

          throw error;
        });
      }
    }
}
</script>