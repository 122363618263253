import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSignOutAlt, faFolder, faFileAlt, faTrashAlt, faFolderPlus, faEdit, faCloudUploadAlt, faUserLock, faUser, faUserPlus, faSortUp, faSortDown, faSlidersH} from '@fortawesome/free-solid-svg-icons';
library.add(faSignOutAlt, faFolder, faFileAlt, faTrashAlt, faFolderPlus, faEdit, faCloudUploadAlt, faUserLock, faUser, faUserPlus, faSortUp, faSortDown, faSlidersH);

const vueApp = createApp(App).use(router).mount('#app')