<template>
  <div class="bg-light">
    <div id="nav" class="container-fluid bg-white border-bottom shadow-sm pl-3 pr-3 pt-1 pb-1">
      <MLSNav :user='user' :navTab.sync='navTab'/>
    </div>
    <div id="body-container" class="container bg-white border-bottom shadow-sm min-vh-100 mt-2">
        <div class="row pt-4 justify-content-md-center">
            <div v-if="this.navTab.name === 'navReports'" class="col col-8-auto">
              <Folders :user='user'/>
            </div>
            <div v-if="this.navTab.name === 'navSearch'" class="col col-8-auto">
              <Search :user='user'/>
            </div>
            <div v-if="this.navTab.name === 'navAdmin'" class="col col-8-auto">
              <Admin :user='user'/>
            </div>
        </div>
    </div>
  </div>
</template>

<script>


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import MLSNav from '../views/MLSNav.vue'
import Folders from '../views/Folders.vue'
import Admin from '../views/Admin.vue'
import Search from '../views/Search.vue'


import axios from 'axios'

export default { 
  components : { FontAwesomeIcon, MLSNav, Folders, Search, Admin },
  data() {
    return {
      navTab: {}
    }
  },
  props : ['user', 'chosenTab'],
  created() {
    this.navTab = this.chosenTab;
  },
  methods : {}
};
</script>
