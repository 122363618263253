<template>
    <div class="signin">
        <LogoBanner />
        <h4 class="mb-3">Marchwood Laboratory Test Results Document Portal</h4>
        <h6>Please login to proceed.</h6>
        <form id="signinForm" class="text-center form-signin" @submit.prevent="submit" novalidate>
            <input id="_username" class="form-control" type="text" name="username" placeholder="User ID" v-model="fields.username"/>
            <div v-if="errors && errors.username" class="text-danger error">{{ errors.username[0] }}</div>
            <input id="_password" class="form-control" type="password" name="password" placeholder="Password" v-model="fields.password">
            <div v-if="errors && errors.password" class="text-danger error">{{ errors.password[0] }}</div>
            <button class="btn btn-lg btn-primary submit-button" type="submit">Login</button>
        </form>
    </div>
</template>

<style>
    .signin {
        padding-top:40px;
        padding-bottom:40px;
    }
    .signin h4 {
        padding-top:40px;
        padding-bottom:40px;
    }
    .form-signin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
    .form-signin .checkbox {
        font-weight: 400;
    }
    .form-signin .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    .form-signin .form-control:focus {
        z-index: 2;
    }
    .form-signin input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .form-signin input[type="password"] {
        margin-bottom: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .text-danger.error {
        margin-bottom: 10px;
    }
    .submit-button {
        margin-top: 10px;
    }
</style>

<script>

import LogoBanner from '@/views/LogoBanner.vue';
import axios from 'axios';

export default {
    components : {
        LogoBanner,
        axios
    },
    data() {
        return {
            fields: {},
            errors: {},
        }
    },
    methods: {
        submit() {
            this.errors = {};
            var err = false;
            if (!this.fields.username || this.fields.username == "") {
                this.errors.username = [];
                this.errors.username.push("Please input User ID");
                err = true;
            }

            if (!this.fields.password || this.fields.password == "") {
                this.errors.password = [];
                this.errors.password.push("Please input Password");
                err = true;
            }

                // console.log("errors:", this.errors);
                // console.log("router", this.$router);
            if (!err) {
                let loginUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_USER_LOGIN;
                axios.post(loginUrl, 
                {
                    username: this.fields.username,
                    password: this.fields.password
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true
                }
                )
                .then(response => {
                    console.log("logged in");
                    this.$router.push("/portal");
                })
                .catch(error => {
                    this.errors.password = [];
                    this.errors.password.push(error?.response?.data?.message);
                });
            }
        }
    }
};
</script>