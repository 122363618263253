<template>
    <div class="card text-left">
        <div class="card-body">
            <div class="row">
                <div class="col col-8-auto">
                    <h5 class="card-title font-weight-bold">
                        System Accounts
                    </h5>
                </div>
                <div class="col col-4 text-right">
                    <a href="" class="mr-3" title="Add User" data-toggle="modal" data-target="#addusermodal"><FontAwesomeIcon icon="user-plus" size="lg"/></a>
                </div>
            </div>
            <table class="table table-striped mt-2 table-hover">
                <thead>
                   <tr>
                        <th scope="col">
                            Username
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">
                            Email
                        </th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(user) in users" :key="user.userId">
                        <td>{{user.username}}</td>
                        <td>{{user.name}}</td>
                        <td>{{user.email}}</td>
                        <td>
                            <a href="" class="mr-3" data-toggle="modal" data-target="#editusermodal" @click="this.retrieveUser(user);"><FontAwesomeIcon icon="edit"/></a>
                            <a href="" class="mr-3" data-toggle="modal" data-target="#deleteusermodal" @click="this.selectedUser=user"><FontAwesomeIcon icon="trash-alt"/></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- User add modal -->
        <div class="modal" tabindex="-1" role="dialog" id="addusermodal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add Account</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="text-danger" v-if="addUserError != ''"> {{ addUserError }} </p>
                        <p>
                            <input type="text" class="form-control" v-model="newUser.username" placeholder="Username"/>
                            <input type="text" class="form-control" v-model="newUser.name" placeholder="Name"/>
                            <input type="text" class="form-control" v-model="newUser.email" placeholder="Email"/>
                            <input type="password" class="form-control" v-model="newUser.password" placeholder="Password"/>
                            <input type="checkbox" id="isAdmin" name="isAdmin" v-model="newUser.admin">&nbsp;<label for="isAdmin">Is user an admin?</label>               
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" v-on:click="addUser">Add</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- User Edit modal -->
        <div class="modal" tabindex="-1" role="dialog" id="editusermodal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Edit User</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="text-danger" v-if="editUserError != ''"> {{ editUserError }} </p>
                        <p><strong>Account: {{ selectedUser.username }}</strong></p>
                        <p>
                            Name: <input type="text" class="form-control" v-model="selectedUser.name" placeholder="Name"/>
                        </p>
                        <p>
                            Email: <input type="text" class="form-control" v-model="selectedUser.email" placeholder="Email"/>
                        </p>
                        <p>
                            Password: <input type="password" class="form-control" v-model="selectedUser.password" placeholder="Password. Blank to retain current password"/>
                        </p>
                        <input type="checkbox" id="editIsAdmin" name="editIsAdmin" v-model="selectedUser.admin">&nbsp;<label for="editIsAdmin">Is user an admin?</label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" v-on:click="editUser">Edit</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- User Delete modal -->
        <div class="modal" tabindex="-1" role="dialog" id="deleteusermodal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Delete Account</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="text-danger" v-if="deleteUserError != ''"> {{ deleteUserError }} </p>
                        <p class="alert alert-danger">
                            Are you sure you want to delete this account?
                            <br>
                            <br>
                            <strong>Username:</strong> {{ selectedUser.username }}
                            <br>
                            <strong>Name:</strong> {{ selectedUser.name }}
                            <br>
                            <strong>Email:</strong> {{ selectedUser.email }}
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" v-on:click="deleteUser">Delete</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.dndfileupload::before {
    content: attr(data-title);
    visibility: visible;
}
.dndfileupload {
    visibility: hidden;
}
.clickable {
    cursor: pointer;
    color: #0078FF;
}
.clickable:hover {
    color: #0156B3
}
.text-danger {
    white-space: pre-wrap;
}
#isAdmin {
    margin-left: 5px;
    margin-top: 20px;
}
</style>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import owasp from 'owasp-password-strength-test';

owasp.config({
    allowPassphrases: true,
    maxLength: 128,
    minLength: 8,
    minPhraseLength: 20,
    minOptionalTestsToPass: 4
});

const $ = require('jquery');
const axios = require('axios');

export default {
    components : { FontAwesomeIcon, owasp },
    mounted() {
        const _this = this;

        // clear data in add folder modal
        $('#addusermodal').on('hide.bs.modal', function(e) {
            _this.addUserError = "";
            _this.newUser = {};
        });

        // clear data in upload folder modal
        $('#editusermodal').on('hide.bs.modal', function(e) {
            _this.editUserError = "";
            _this.selectedUser = {};
            _this.editedUser = "";
        });

        this._getUsers();
    },
    data() {
        return {
            users: [],
            errors: {},
            newUser: {},
            addUserError: "",
            editUserError: "",
            selectedUser: {},
            editedUser: {},
            deleteUserError: ""
        }
    },
    props: ['user'],
    methods: {
        addUser() {
            var success = true;
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!this.newUser.username) {
                this.addUserError = "Please input username";
                success = false;
            } else if (!this.newUser.name) {
                this.addUserError = "Please input name";
                success = false;
            } else if (!this.newUser.email) {
                this.addUserError = "Please input email";
                success = false;
            } else if (!re.test(this.newUser.email)) {
                this.addUserError = "Invalid email format";
                success = false;
            } else if (!this.newUser.password) {
                this.addUserError = "Please input password";
                success = false;
            } else {
                //check password strength
                let passTestResult = owasp.test(this.newUser.password);
                if (passTestResult.errors && passTestResult.errors.length > 0) {
                    let error = ""
                    for(let i = 0; i < passTestResult.errors.length; i++){
                        error += "- " + passTestResult.errors[i];
                        if(i < passTestResult.errors.length - 1){
                            error += "\n";
                        }
                    }
                    this.addUserError = error;
                    success = false;
                }
            }

            if (success) {
                let createUserUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_ADMIN_CREATE_USER;
                axios.post(createUserUrl, 
                {
                    "username": this.newUser.username,
                    "name": this.newUser.name,
                    "email": this.newUser.email,
                    "password": this.newUser.password,
                    "admin": (this.newUser.admin == true)
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true
                }
                )
                .then(response => {
                    this.users = this._getUsers();

                    $('#addusermodal').modal('hide');
                })
                .catch(error => {
                    this.addUserError = error.response.data.message;
                    if(error.response.data.statusCode == 440) {
                        this.$router.push("/login");
                    }
                });
            }
        },
        retrieveUser(user) {
            let retrieveUserUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_ADMIN_RETRIEVE_USER;
            retrieveUserUrl += "/" + user.userId;
            axios.get(retrieveUserUrl, 
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            }
            )
            .then(response => {
                this.selectedUser = response.data;
                return this.selectedUser;
            })
            .catch(error => {
                console.log("Error retrieving user");
                if(error.response.data.statusCode == 440) {
                    this.$router.push("/login");
                }
            });
        },
        editUser() {
            var success = true;
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!this.selectedUser.name) {
                this.editUserError = "Please input name";
                success = false;
            } else if (!this.selectedUser.email) {
                this.editUserError = "Please input email";
                success = false;
            } else if (!re.test(this.selectedUser.email)) {
                this.editUserError = "Invalid email format";
                success = false;
            } else {
                if (this.selectedUser.password) {
                    //check password strength
                    let passTestResult = owasp.test(this.selectedUser.password);
                    if (passTestResult.errors && passTestResult.errors.length > 0) {
                        let error = ""
                        for(let i = 0; i < passTestResult.errors.length; i++){
                            error += "- " + passTestResult.errors[i];
                            if(i < passTestResult.errors.length - 1){
                                error += "\n";
                            }
                        }
                        this.editUserError = error;
                        success = false;
                    }
                }
            }

            if (success) {
                let updateUserUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_ADMIN_UPDATE_USER;
                updateUserUrl += "/" + this.selectedUser.userId;
                axios.put(updateUserUrl, 
                {
                    "name": this.selectedUser.name,
                    "email": this.selectedUser.email,
                    "password": this.selectedUser.password,
                    "admin": (this.selectedUser.admin == true)
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true
                }
                )
                .then(response => {
                    this.users = this._getUsers();

                    $('#editusermodal').modal('hide');
                })
                .catch(error => {
                    this.editUserError = error.response.data.message;
                    if(error.response.data.statusCode == 440) {
                        this.$router.push("/login");
                    }
                });
            }
        },
        deleteUser() {
            // TODO: Delete folder
            var success = true;
            if (success) {
                let deleteUserUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_ADMIN_DELETE_USER;
                deleteUserUrl += "/" + this.selectedUser.userId;
                axios.delete(deleteUserUrl,
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true
                }
                )
                .then(response => {
                    this.users = this._getUsers();

                    $('#deleteusermodal').modal('hide');
                })
                .catch(error => {
                    this.deleteUserError = error.response.data.message;
                });
            } else {
                this.deleteUserError = "Error deleting folder!";
                if(error.response.data.statusCode == 440) {
                    this.$router.push("/login");
                }
            }
        },
        _getUsers() {
            let listUsersUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_ADMIN_LIST_USERS;
            axios.get(listUsersUrl, 
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            }
            )
            .then(response => {
                this.users = response.data.users;
                return this.users;
            })
            .catch(error => {
                console.log("Error listing user");
                if(error.response.data.statusCode == 440) {
                    this.$router.push("/login");
                }
            });
        }
    }
}
</script>