<template>
    <div class="card text-left">
        <div class="card-body">
            <div class="row">
                <div class="col col-8-auto">
                    <h5 class="card-title font-weight-bold">
                        Search
                    </h5>
                </div>
                <div class="col col-12 text-left">
                    <input type="text" class="col-8 mr-2" maxlength="50" v-model="searchReportName" placeholder="Report name to search for">
                    <button type="button" class="btn btn-primary" v-on:click="searchReports">Search</button>
                    <p class="text-danger" v-if="searchReportError != ''"> {{ searchReportError }} </p>
                </div>
            </div>
            <table class="table table-striped mt-2 table-hover">
                <thead>
                   <tr>
                        <th scope="col"></th>
                        <th scope="col">
                            Name
                        </th>
                        <th scope="col">Size</th>
                        <th scope="col">
                            Modified
                        </th>
                        <th scope="col" v-if="user.admin">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item) in reports" :key="item.id">
                        <td>
                            <FontAwesomeIcon icon="file-alt"/>
                        </td>
                        <td><a href="#" v-on:click.stop.prevent="itemSelected(item)">{{item.name}}</a></td>
                        <td><span>{{item.size}}</span></td>
                        <td>{{item.modified}}</td>
                        <td v-if="user.admin">
                            <a href="" class="mr-3" data-toggle="modal" data-target="#deletereportmodal" @click="this.selectedItem=item"><FontAwesomeIcon icon="trash-alt"/></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center" v-if="(reports == null || reports.length <=0)">
                No records found.
            </div>
        </div>
        <!-- Folder Delete modal -->
        <div class="modal" tabindex="-1" role="dialog" id="deletereportmodal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-if="selectedItem.type == 'report'">Delete Report</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="text-danger" v-if="deleteReportError != ''"> {{ deleteReportError }} </p>
                        <p class="alert alert-danger" v-if="selectedItem.type == 'report'">Are you sure you want to delete <strong>'{{selectedItem.name}}'</strong>?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" v-on:click="deleteReport(selectedItem)">Delete</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.clickable {
    cursor: pointer;
    color: #0078FF;
}
.clickable:hover {
    color: #0156B3
}
.filter-button {
    display: inline;
    float: right;
    clear: both;
}
#reportFilter {
    margin-top: 10px;
}
.filter-apply-div {
    margin-top: 10px;
}
.bg-green {
    background-color: lightgreen;
}
.hidden {
    display: none;
}
.underline {
    text-decoration: underline;
}
.cursor-pointer {
    cursor: pointer;
}
.remove-file-button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: red;
    font-weight: bold;
}
</style>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moment from 'moment'

const $ = require('jquery');
const axios = require('axios');

export default {
    components : { FontAwesomeIcon, moment },
    mounted() {
        const _this = this;

        _this.searchReportName = "";
    },
    data() {
        return {
            searchReportName: "",
            reports: [],
            searchReportError: "",
            deleteReportError: "",
            selectedItem: "",
        }
    },
    props: ['user'],
    methods: {
        itemSelected(item) {
            if (item.type == "report") {
                // TODO: Download the file from backend
                this.downloadFile(item);
            }
        },
        downloadFile(item) {
            let getContentsUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_RETRIEVE_REPORT;
            getContentsUrl += "/" + item.id;
            axios.get(getContentsUrl, 
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            }
            )
            .then(response => {
                let a = document.createElement("a");
                a.href = "data:" + response.headers["content-type"] +";base64," + response.data.file;
                a.download = response.data.fileName;
                a.click();
            })
            .catch(error => {
                console.log("Error downloading file");
                console.log(error);
                if(error.response.data.statusCode == 440) {
                    this.$router.push("/login");
                }
            });
        },
        deleteReport(report) {
            // TODO: Delete folder
            var success = true;
            if (success) {
                let deleteReportUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_DELETE_REPORT;
                deleteReportUrl += "/" + report.id;
                axios.delete(deleteReportUrl, 
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true
                }
                )
                .then(response => {
                    // update items after delete
                    this.searchReports();

                    $('#deletereportmodal').modal('hide');
                })
                .catch(error => {
                    this.deleteReportError = error.response.data.message;
                    if(error.response.data.statusCode == 440) {
                        this.$router.push("/login");
                    }
                });
            } else {
                this.deleteReportError = "Error deleting report!";
            }
        },
        searchReports() {
            var success = true;
            if (!this.searchReportName) {
                this.searchReportError = "Please input file name to search";
                success = false;
            } else if (this.searchReportName.length < 3 || this.searchReportName.length > 30) {
                this.searchReportError = "File name must be between 3 to 30 characters";
                success = false;
            }

            if(success){
                console.log("searching reports");
                let searchReportsUrl = process.env.VUE_APP_API_DOMAIN + process.env.VUE_APP_API_PATH_SEARCH_REPORT;
                searchReportsUrl += "?fileName=" + this.searchReportName;
                axios.get(searchReportsUrl, 
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        withCredentials: true
                    }
                )
                .then(response => {
                    console.log(response);
                    let reports = [];
                    for(let report of response.data) {
                        let item = {
                            "id": report.reportId,
                            "name": report.fileName,
                            "size": (report.fileSize / 1000).toFixed(1) + "KB",
                            "modified": moment(report.modifiedDate).format("DD/MM/YYYY HH:mm:ss"),
                            "type": "report"
                        }
                        reports.push(item);
                    }
                    reports.sort(this.sortItems);
                    this.reports = reports;

                    this.searchReportError = "";

                    return true;
                })
                .catch(error => {
                    console.log("Error listing folders");
                    if(error.response.data.statusCode == 440) {
                        this.$router.push("/login");
                    }
                });
            }
        }
    }
}
</script>